// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddonLicenseStatus, LicenseInfo, LicenseProduct } from './';

/**
 * @export
 * @interface LicenseOverviewResponse
 */
export interface LicenseOverviewResponse {
  /**
   * @type {AddonLicenseStatus}
   * @memberof LicenseOverviewResponse
   */
  addonLicenseStatus?: AddonLicenseStatus;
  /**
   * @type {number}
   * @memberof LicenseOverviewResponse
   */
  applianceCount?: number;
  /**
   * @type {Array<LicenseInfo>}
   * @memberof LicenseOverviewResponse
   */
  assignedLicenses?: Array<LicenseInfo>;
  /**
   * @type {string}
   * @memberof LicenseOverviewResponse
   */
  licenseOverviewState?: LicenseOverviewResponseLicenseOverviewStateEnum;
  /**
   * @type {number}
   * @memberof LicenseOverviewResponse
   */
  licensedCount?: number;
  /**
   * @type {Array<string>}
   * @memberof LicenseOverviewResponse
   */
  lockedMeasureSites?: Array<string>;
  /**
   * @type {Array<LicenseProduct>}
   * @memberof LicenseOverviewResponse
   */
  products?: Array<LicenseProduct>;
  /**
   * @type {number}
   * @memberof LicenseOverviewResponse
   */
  unassignedLicenseCount?: number;
  /**
   * @type {Array<LicenseInfo>}
   * @memberof LicenseOverviewResponse
   */
  unassignedLicenses?: Array<LicenseInfo>;
  /**
   * @type {Array<string>}
   * @memberof LicenseOverviewResponse
   */
  unlicensedApplianceIds?: Array<string>;
  /**
   * @type {number}
   * @memberof LicenseOverviewResponse
   */
  unlicensedCount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum LicenseOverviewResponseLicenseOverviewStateEnum {
  CURRENT = 'CURRENT',
  CACHED = 'CACHED',
  FAILED = 'FAILED'
}
